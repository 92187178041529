import { AppService } from '@api/app/v1/app_connectweb';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { useCallback, useEffect, useState } from 'react';

import getCurrentEnv from '@/utils/getCurrentEnv';

export default function useAppService(): () => Promise<
  PromiseClient<typeof AppService>
> {
  const [client, setClient] = useState<PromiseClient<typeof AppService> | null>(
    null,
  );

  const getClient = useCallback(async () => {
    if (client) {
      return client;
    } else {
      const isDevEnv = getCurrentEnv() === 'development';
      const baseUrl = isDevEnv ? 'http://localhost:8080/' : '/';
      const transport = createConnectTransport({ baseUrl });

      const newClient = createPromiseClient(AppService, transport);
      setClient(newClient);
      return newClient;
    }
  }, [client]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  return getClient;
}
