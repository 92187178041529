import getCurrentEnv from '@/utils/getCurrentEnv';

const scratchFirebaseCredentials = {
  apiKey: 'AIzaSyB2xqRwWGZk09SwDICLTBPzd5uGA18TXIw',
  authDomain: 'auth.scratch.sheerhealth.com',
  projectId: 'sheer-health-scratch',
  storageBucket: 'sheer-health-scratch.appspot.com',
  messagingSenderId: '465819884967',
  appId: '1:465819884967:web:fd2e53353b6dc34c862a82',
  measurementId: 'G-Z3G0C5621C',
};

const stagingFirebaseCredentials = {
  apiKey: 'AIzaSyALf_AWVLqRy48WOQN3gVHBKNlh6uos6nU',
  authDomain: 'auth.staging.sheerhealth.com',
  projectId: 'optimum-nebula-375615',
  storageBucket: 'optimum-nebula-375615.appspot.com',
  messagingSenderId: '718277904918',
  appId: '1:718277904918:web:9222cabae41bc91381c337',
  measurementId: 'G-JP4G4SF4PK',
};

const prodFirebaseCredentials = {
  apiKey: 'AIzaSyBwHhs-_X6ta0Ha5EeruTWV_oDXOZ-72M0',
  authDomain: 'auth.sheerhealth.com',
  projectId: 'big-vertex-372416',
  storageBucket: 'big-vertex-372416.appspot.com',
  messagingSenderId: '110118697051',
  appId: '1:110118697051:web:2b1c59bb52e08a6fabe77e',
  measurementId: 'G-5N3E6Z8EVB',
};

const betaScratchFirebaseCredentials = {
  apiKey: 'AIzaSyB2xqRwWGZk09SwDICLTBPzd5uGA18TXIw',
  authDomain: 'sheer-health-scratch.firebaseapp.com',
  projectId: 'sheer-health-scratch',
  storageBucket: 'sheer-health-scratch.appspot.com',
  messagingSenderId: '465819884967',
  appId: '1:465819884967:web:fd2e53353b6dc34c862a82',
  measurementId: 'G-Z3G0C5621C',
};

const betaStagingFirebaseCredentials = {
  apiKey: 'AIzaSyALf_AWVLqRy48WOQN3gVHBKNlh6uos6nU',
  authDomain: 'optimum-nebula-375615.firebaseapp.com',
  projectId: 'optimum-nebula-375615',
  storageBucket: 'optimum-nebula-375615.appspot.com',
  messagingSenderId: '718277904918',
  appId: '1:718277904918:web:9222cabae41bc91381c337',
  measurementId: 'G-JP4G4SF4PK',
};

const betaProdFirebaseCredentials = {
  apiKey: 'AIzaSyBwHhs-_X6ta0Ha5EeruTWV_oDXOZ-72M0',
  authDomain: 'big-vertex-372416.firebaseapp.com',
  projectId: 'big-vertex-372416',
  storageBucket: 'big-vertex-372416.appspot.com',
  messagingSenderId: '110118697051',
  appId: '1:110118697051:web:2b1c59bb52e08a6fabe77e',
  measurementId: 'G-5N3E6Z8EVB',
};

const firebase = {
  scratch: scratchFirebaseCredentials,
  beta_scratch: betaScratchFirebaseCredentials,
  staging: stagingFirebaseCredentials,
  beta_staging: betaStagingFirebaseCredentials,
  production: prodFirebaseCredentials,
  beta_production: betaProdFirebaseCredentials,
};

const getCredentials = () => {
  const currentEnv = getCurrentEnv();

  switch (currentEnv) {
    case 'beta_staging':
      return firebase.beta_staging;
    case 'staging':
      return firebase.staging;
    case 'beta_production':
      return firebase.beta_production;
    case 'production':
      return firebase.production;
    case 'beta_scratch':
      return firebase.beta_scratch;
    case 'scratch':
    case 'development':
    default:
      return firebase.scratch;
  }
};

export default getCredentials;
