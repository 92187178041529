// @generated by protoc-gen-es v1.10.0 with parameter "target=js+ts+dts"
// @generated from file app/v1/app.proto (package app.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * The type of a payer.
 *
 * @generated from enum app.v1.PayerType
 */
export const PayerType = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.PayerType",
  [
    {no: 0, name: "PAYER_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PAYER_TYPE_MEDICAL", localName: "MEDICAL"},
    {no: 2, name: "PAYER_TYPE_DENTAL", localName: "DENTAL"},
    {no: 3, name: "PAYER_TYPE_VISION", localName: "VISION"},
    {no: 4, name: "PAYER_TYPE_FERTILITY", localName: "FERTILITY"},
    {no: 5, name: "PAYER_TYPE_PHARMACY", localName: "PHARMACY"},
    {no: 6, name: "PAYER_TYPE_MY_CHART", localName: "MY_CHART"},
  ],
);

/**
 * The type of a Sheer support case (formerly called event).
 *
 * @generated from enum app.v1.CaseType
 */
export const CaseType = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.CaseType",
  [
    {no: 0, name: "CASE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "CASE_TYPE_QUESTION", localName: "QUESTION"},
    {no: 2, name: "CASE_TYPE_CLAIM", localName: "CLAIM"},
    {no: 3, name: "CASE_TYPE_BILL_QUESTION", localName: "BILL_QUESTION"},
    {no: 4, name: "CASE_TYPE_BENEFITS_QUESTION", localName: "BENEFITS_QUESTION"},
    {no: 5, name: "CASE_TYPE_OON_CLAIM", localName: "OON_CLAIM"},
    {no: 6, name: "CASE_TYPE_AUTHORIZATION", localName: "AUTHORIZATION"},
    {no: 7, name: "CASE_TYPE_REVIEW", localName: "REVIEW"},
    {no: 8, name: "CASE_TYPE_BILL", localName: "BILL"},
    {no: 9, name: "CASE_TYPE_PRESCRIPTION", localName: "PRESCRIPTION"},
    {no: 10, name: "CASE_TYPE_REIMBURSEMENT", localName: "REIMBURSEMENT"},
    {no: 11, name: "CASE_TYPE_COST_CHECK", localName: "COST_CHECK"},
    {no: 12, name: "CASE_TYPE_PLAN_SELECTION", localName: "PLAN_SELECTION"},
    {no: 13, name: "CASE_TYPE_MEDICAL_PLAN_SELECTION", localName: "MEDICAL_PLAN_SELECTION"},
  ],
);

/**
 * Source of a message.
 *
 * @generated from enum app.v1.Source
 */
export const Source = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.Source",
  [
    {no: 0, name: "SOURCE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SOURCE_WEB", localName: "WEB"},
    {no: 2, name: "SOURCE_IOS", localName: "IOS"},
    {no: 3, name: "SOURCE_ANDROID", localName: "ANDROID"},
  ],
);

/**
 * HealthProfileType to represent a health profile.
 *
 * @generated from enum app.v1.HealthProfileType
 */
export const HealthProfileType = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.HealthProfileType",
  [
    {no: 0, name: "HEALTH_PROFILE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "HEALTH_PROFILE_TYPE_FERTILITY_PREGNANCY", localName: "FERTILITY_PREGNANCY"},
    {no: 2, name: "HEALTH_PROFILE_TYPE_PEDIATRIC_CARE", localName: "PEDIATRIC_CARE"},
    {no: 3, name: "HEALTH_PROFILE_TYPE_RECURRING_PRESCRIPTIONS", localName: "RECURRING_PRESCRIPTIONS"},
    {no: 4, name: "HEALTH_PROFILE_TYPE_MENTAL_HEALTH", localName: "MENTAL_HEALTH"},
    {no: 5, name: "HEALTH_PROFILE_TYPE_SURGERY_PROCEDURES", localName: "SURGERY_PROCEDURES"},
    {no: 6, name: "HEALTH_PROFILE_TYPE_LABS_TESTING", localName: "LABS_TESTING"},
    {no: 7, name: "HEALTH_PROFILE_TYPE_REHABILITATIVE_THERAPY", localName: "REHABILITATIVE_THERAPY"},
    {no: 8, name: "HEALTH_PROFILE_TYPE_ONCOLOGY", localName: "ONCOLOGY"},
    {no: 9, name: "HEALTH_PROFILE_TYPE_FERTILITY", localName: "FERTILITY"},
    {no: 10, name: "HEALTH_PROFILE_TYPE_PREGNANCY", localName: "PREGNANCY"},
    {no: 11, name: "HEALTH_PROFILE_TYPE_DENTAL", localName: "DENTAL"},
  ],
);

/**
 * A Member of the Sheer platform.
 *
 * @generated from message app.v1.Member
 */
export const Member = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Member",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "date_of_birth", kind: "message", T: Timestamp },
    { no: 6, name: "assigned_sex", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "registered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "amount_saved", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "waitlisted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "interested_in_betas", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "health_profiles", kind: "enum", T: proto3.getEnumType(HealthProfileType), repeated: true },
  ],
);

/**
 * Note about a member's registration.
 *
 * @generated from message app.v1.RegistrationNote
 */
export const RegistrationNote = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegistrationNote",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "primary_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "primary_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "primary_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "primary_date_of_birth", kind: "message", T: Timestamp },
    { no: 7, name: "portal_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "portal_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "referral_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "membership_tier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "referrer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * A Plan that a member is enrolled in.
 *
 * @generated from message app.v1.Plan
 */
export const Plan = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Plan",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "merchant_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
    { no: 6, name: "faulted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * An Event that has occurred.
 *
 * @generated from message app.v1.Event
 */
export const Event = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Event",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "messages", kind: "message", T: EventMessage, repeated: true },
    { no: 7, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "creator_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "creator_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "creator_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "created_at", kind: "message", T: Timestamp },
    { no: 12, name: "amount_saved", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "form_fields", kind: "message", T: Event_FormField, repeated: true },
  ],
);

/**
 * The type of a pending field.
 *
 * @generated from enum app.v1.Event.FieldType
 */
export const Event_FieldType = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.Event.FieldType",
  [
    {no: 0, name: "FIELD_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "FIELD_TYPE_TEXT", localName: "TEXT"},
    {no: 2, name: "FIELD_TYPE_SINGLE_SELECT", localName: "SINGLE_SELECT"},
    {no: 3, name: "FIELD_TYPE_MULTI_SELECT", localName: "MULTI_SELECT"},
    {no: 4, name: "FIELD_TYPE_MONEY", localName: "MONEY"},
    {no: 5, name: "FIELD_TYPE_BOOLEAN", localName: "BOOLEAN"},
    {no: 6, name: "FIELD_TYPE_FILE", localName: "FILE"},
    {no: 7, name: "FIELD_TYPE_PLAN_ID", localName: "PLAN_ID"},
  ],
);

/**
 * The form field details.
 *
 * @generated from message app.v1.Event.FormField
 */
export const Event_FormField = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Event.FormField",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "field_type", kind: "enum", T: proto3.getEnumType(Event_FieldType) },
    { no: 4, name: "field_options", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "pending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "optional", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Event_FormField"},
);

/**
 * Detail about an Event.
 *
 * @generated from message app.v1.EventMessage
 */
export const EventMessage = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.EventMessage",
  () => [
    { no: 1, name: "updated_at", kind: "message", T: Timestamp },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "creator_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "creator_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "creator_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "attachment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Information about a payer.
 *
 * @generated from message app.v1.Payer
 */
export const Payer = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Payer",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "supported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "types", kind: "enum", T: proto3.getEnumType(PayerType), repeated: true },
  ],
);

/**
 * GetMemberTokenRequest to get a member token, suitable for making calls on behalf of the member.
 *
 * @generated from message app.v1.GetMemberTokenRequest
 */
export const GetMemberTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetMemberTokenRequest",
  [],
);

/**
 * GetMemberTokenResponse containing the member token.
 *
 * @generated from message app.v1.GetMemberTokenResponse
 */
export const GetMemberTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetMemberTokenResponse",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * GetCurrentMemberRequest to get the current member's profile.
 *
 * @generated from message app.v1.GetCurrentMemberRequest
 */
export const GetCurrentMemberRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetCurrentMemberRequest",
  [],
);

/**
 * GetCurrentMemberResponse containing the current member's profile.
 *
 * @generated from message app.v1.GetCurrentMemberResponse
 */
export const GetCurrentMemberResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetCurrentMemberResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * RegisterMemberRequest to register a new member.
 *
 * @generated from message app.v1.RegisterMemberRequest
 */
export const RegisterMemberRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterMemberRequest",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
    { no: 2, name: "user_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterMemberResponse containing the new member's profile.
 *
 * @generated from message app.v1.RegisterMemberResponse
 */
export const RegisterMemberResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterMemberResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * RegisterNotesRequest to add notes to a member's registration.
 *
 * @generated from message app.v1.RegisterNotesRequest
 */
export const RegisterNotesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterNotesRequest",
  () => [
    { no: 1, name: "notes", kind: "message", T: RegistrationNote },
  ],
);

/**
 * RegisterNotesResponse containing the result of adding notes to a member's registration.
 *
 * @generated from message app.v1.RegisterNotesResponse
 */
export const RegisterNotesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterNotesResponse",
  [],
);

/**
 * RegisterDeviceRequest to register a device for push notifications.
 *
 * @generated from message app.v1.RegisterDeviceRequest
 */
export const RegisterDeviceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterDeviceRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterDeviceResponse containing the result of registering a device for push notifications.
 *
 * @generated from message app.v1.RegisterDeviceResponse
 */
export const RegisterDeviceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterDeviceResponse",
  [],
);

/**
 * GetAmountSavedRequest to get the amount saved for the current member.
 *
 * @generated from message app.v1.GetAmountSavedRequest
 */
export const GetAmountSavedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetAmountSavedRequest",
  [],
);

/**
 * GetAmountSavedResponse containing the amount saved for the current member.
 *
 * @generated from message app.v1.GetAmountSavedResponse
 */
export const GetAmountSavedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetAmountSavedResponse",
  () => [
    { no: 1, name: "amount_saved", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * AddPlanRequest to add a plan for the current member.
 *
 * @generated from message app.v1.AddPlanRequest
 */
export const AddPlanRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddPlanRequest",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * AddPlanResponse containing the result of adding a plan for the current member.
 *
 * @generated from message app.v1.AddPlanResponse
 */
export const AddPlanResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddPlanResponse",
  [],
);

/**
 * ListPlansRequest to list plans for the current member.
 *
 * @generated from message app.v1.ListPlansRequest
 */
export const ListPlansRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListPlansRequest",
  [],
);

/**
 * ListPlansResponse containing the plans for the current member.
 *
 * @generated from message app.v1.ListPlansResponse
 */
export const ListPlansResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListPlansResponse",
  () => [
    { no: 1, name: "plans", kind: "message", T: Plan, repeated: true },
  ],
);

/**
 * SummarizePlansRequest to summarize plans for the current member.
 *
 * @generated from message app.v1.SummarizePlansRequest
 */
export const SummarizePlansRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SummarizePlansRequest",
  [],
);

/**
 * SummarizePlansResponse containing the plans for the current member.
 *
 * @generated from message app.v1.SummarizePlansResponse
 */
export const SummarizePlansResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SummarizePlansResponse",
  () => [
    { no: 1, name: "plans", kind: "message", T: PlanSummary, repeated: true },
  ],
);

/**
 * PlanSummary containing the plans for the current member.
 *
 * @generated from message app.v1.PlanSummary
 */
export const PlanSummary = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.PlanSummary",
  () => [
    { no: 1, name: "plan_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "has_medical_booklet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "has_sbc", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "primary_subscriber_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterCredentialsRequest to register credentials for the current member.
 *
 * @generated from message app.v1.RegisterCredentialsRequest
 */
export const RegisterCredentialsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterCredentialsRequest",
  () => [
    { no: 1, name: "merchant_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_blob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterCredentialsResponse containing the result of registering credentials for the current member.
 *
 * @generated from message app.v1.RegisterCredentialsResponse
 */
export const RegisterCredentialsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterCredentialsResponse",
  () => [
    { no: 1, name: "collection_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "continue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "date_from", kind: "message", T: Timestamp },
  ],
);

/**
 * RegisterCredentialsV2Request to register credentials for the current member.
 *
 * @generated from message app.v1.RegisterCredentialsV2Request
 */
export const RegisterCredentialsV2Request = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterCredentialsV2Request",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "session_blob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterCredentialsV2Response containing the result of registering credentials for the current member.
 *
 * @generated from message app.v1.RegisterCredentialsV2Response
 */
export const RegisterCredentialsV2Response = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterCredentialsV2Response",
  () => [
    { no: 1, name: "collection_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "continue", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "date_from", kind: "message", T: Timestamp },
  ],
);

/**
 * CollectRecordsRequest to collect records for the current member.
 *
 * @generated from message app.v1.CollectRecordsRequest
 */
export const CollectRecordsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CollectRecordsRequest",
  () => [
    { no: 1, name: "results", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * CollectRecordsResponse containing the result of collecting records for the current member.
 *
 * @generated from message app.v1.CollectRecordsResponse
 */
export const CollectRecordsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CollectRecordsResponse",
  [],
);

/**
 * SearchPayersRequest to search for payers.
 *
 * @generated from message app.v1.SearchPayersRequest
 */
export const SearchPayersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SearchPayersRequest",
  () => [
    { no: 1, name: "term", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * SearchPayersResponse containing the result of searching for payers.
 *
 * @generated from message app.v1.SearchPayersResponse
 */
export const SearchPayersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SearchPayersResponse",
  () => [
    { no: 1, name: "payers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * SearchPayersV2Request to search for payers.
 *
 * @generated from message app.v1.SearchPayersV2Request
 */
export const SearchPayersV2Request = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SearchPayersV2Request",
  () => [
    { no: 1, name: "term", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * SearchPayersV2Response containing the result of searching for payers.
 *
 * @generated from message app.v1.SearchPayersV2Response
 */
export const SearchPayersV2Response = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SearchPayersV2Response",
  () => [
    { no: 1, name: "payers", kind: "message", T: Payer, repeated: true },
  ],
);

/**
 * StartAuditRequest to start a new audit for the current member.
 *
 * @generated from message app.v1.StartAuditRequest
 */
export const StartAuditRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.StartAuditRequest",
  [],
);

/**
 * StartAuditResponse containing the result of starting an audit for the current member.
 *
 * @generated from message app.v1.StartAuditResponse
 */
export const StartAuditResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.StartAuditResponse",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * AddEventRequest to add an event for the current member.
 *
 * @generated from message app.v1.AddEventRequest
 */
export const AddEventRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventRequest",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "case_type", kind: "enum", T: proto3.getEnumType(CaseType) },
    { no: 3, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * AddEventResponse containing the result of adding an event for the current member.
 *
 * @generated from message app.v1.AddEventResponse
 */
export const AddEventResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventResponse",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "event", kind: "message", T: Event },
  ],
);

/**
 * AddEventMessageRequest to add a message to an event for the current member.
 *
 * @generated from message app.v1.AddEventMessageRequest
 */
export const AddEventMessageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventMessageRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "message", kind: "message", T: EventMessage },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(Source) },
  ],
);

/**
 * AddEventMessageResponse containing the result of adding a message to an event for the current member.
 *
 * @generated from message app.v1.AddEventMessageResponse
 */
export const AddEventMessageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventMessageResponse",
  [],
);

/**
 * AddEventFile to add a file to an event for the current member.
 *
 * @generated from message app.v1.AddEventFileRequest
 */
export const AddEventFileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventFileRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "source", kind: "enum", T: proto3.getEnumType(Source) },
  ],
);

/**
 * AddEventFileResponse containing the result of adding a file to an event for the current member.
 *
 * @generated from message app.v1.AddEventFileResponse
 */
export const AddEventFileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.AddEventFileResponse",
  () => [
    { no: 1, name: "upload_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * SubmitEventFormRequest to fill fields on an event for the current member.
 *
 * @generated from message app.v1.SubmitEventFormRequest
 */
export const SubmitEventFormRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "enum", T: proto3.getEnumType(Source) },
    { no: 4, name: "field_values", kind: "message", T: SubmitEventFormRequest_FieldValue, repeated: true },
    { no: 5, name: "additional_details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The field value details.
 *
 * @generated from message app.v1.SubmitEventFormRequest.FieldValue
 */
export const SubmitEventFormRequest_FieldValue = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormRequest.FieldValue",
  () => [
    { no: 1, name: "field_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "string_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "string_list_value", kind: "message", T: SubmitEventFormRequest_StringListValue, oneof: "value" },
    { no: 5, name: "skipped_value", kind: "message", T: SubmitEventFormRequest_Empty, oneof: "value" },
    { no: 6, name: "double_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "value" },
    { no: 7, name: "boolean_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 8, name: "file_value", kind: "message", T: SubmitEventFormRequest_File, oneof: "value" },
  ],
  {localName: "SubmitEventFormRequest_FieldValue"},
);

/**
 * @generated from message app.v1.SubmitEventFormRequest.StringListValue
 */
export const SubmitEventFormRequest_StringListValue = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormRequest.StringListValue",
  () => [
    { no: 1, name: "values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "SubmitEventFormRequest_StringListValue"},
);

/**
 * Empty message for when we don't have a value.
 *
 * @generated from message app.v1.SubmitEventFormRequest.Empty
 */
export const SubmitEventFormRequest_Empty = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormRequest.Empty",
  [],
  {localName: "SubmitEventFormRequest_Empty"},
);

/**
 * File to be uploaded.
 *
 * @generated from message app.v1.SubmitEventFormRequest.File
 */
export const SubmitEventFormRequest_File = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormRequest.File",
  () => [
    { no: 1, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubmitEventFormRequest_File"},
);

/**
 * SubmitEventFormResponse containing the result of filling fields on an event for the current member.
 *
 * @generated from message app.v1.SubmitEventFormResponse
 */
export const SubmitEventFormResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormResponse",
  () => [
    { no: 1, name: "file_uploads", kind: "message", T: SubmitEventFormResponse_FileUpload, repeated: true },
  ],
);

/**
 * The file upload URLs.
 *
 * @generated from message app.v1.SubmitEventFormResponse.FileUpload
 */
export const SubmitEventFormResponse_FileUpload = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SubmitEventFormResponse.FileUpload",
  () => [
    { no: 1, name: "field_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "upload_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubmitEventFormResponse_FileUpload"},
);

/**
 * RunEventInferenceRequest to trigger ML processing on an event. This may include either
 * extraction of uploaded files or suggesting a response to a question.
 *
 * @generated from message app.v1.RunEventInferenceRequest
 */
export const RunEventInferenceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RunEventInferenceRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RunEventInferenceResponse containing the result of triggering ML processing on an event.
 *
 * @generated from message app.v1.RunEventInferenceResponse
 */
export const RunEventInferenceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RunEventInferenceResponse",
  [],
);

/**
 * DismissEventRequest to dismiss an event.
 *
 * @generated from message app.v1.DismissEventRequest
 */
export const DismissEventRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.DismissEventRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source", kind: "enum", T: proto3.getEnumType(Source) },
  ],
);

/**
 * DismissEventResponse containing the result of dismissing an event.
 *
 * @generated from message app.v1.DismissEventResponse
 */
export const DismissEventResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.DismissEventResponse",
  [],
);

/**
 * ListEventsRequest to list events for the current member.
 *
 * @generated from message app.v1.ListEventsRequest
 */
export const ListEventsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListEventsRequest",
  () => [
    { no: 1, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ListEventsResponse containing the result of listing events for the current member.
 *
 * @generated from message app.v1.ListEventsResponse
 */
export const ListEventsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListEventsResponse",
  () => [
    { no: 1, name: "events", kind: "message", T: Event, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * GetEventFileRequest to get a download link for an event file.
 *
 * @generated from message app.v1.GetEventFileRequest
 */
export const GetEventFileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetEventFileRequest",
  () => [
    { no: 1, name: "attachment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * GetEventFileResponse containing the download url for an event file if it exists.
 *
 * @generated from message app.v1.GetEventFileResponse
 */
export const GetEventFileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetEventFileResponse",
  () => [
    { no: 1, name: "success", kind: "message", T: GetEventFileResponse_Success, oneof: "response" },
    { no: 2, name: "failure", kind: "message", T: GetEventFileResponse_MissingFile, oneof: "response" },
    { no: 3, name: "processing", kind: "message", T: GetEventFileResponse_MissingFile, oneof: "response" },
  ],
);

/**
 * Empty message for when we don't have a file to return.
 *
 * @generated from message app.v1.GetEventFileResponse.MissingFile
 */
export const GetEventFileResponse_MissingFile = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetEventFileResponse.MissingFile",
  [],
  {localName: "GetEventFileResponse_MissingFile"},
);

/**
 * Success containing the download url for an event file.
 *
 * @generated from message app.v1.GetEventFileResponse.Success
 */
export const GetEventFileResponse_Success = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetEventFileResponse.Success",
  () => [
    { no: 1, name: "download_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "filename", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetEventFileResponse_Success"},
);

/**
 * GetDeductiblesRequest to retrieve a member's deductibles.
 *
 * @generated from message app.v1.GetDeductiblesRequest
 */
export const GetDeductiblesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetDeductiblesRequest",
  [],
);

/**
 * GetDeductiblesResponse containing the deductibles for the requested member.
 *
 * @generated from message app.v1.GetDeductiblesResponse
 */
export const GetDeductiblesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetDeductiblesResponse",
  () => [
    { no: 1, name: "deductibles", kind: "message", T: Deductible, repeated: true },
  ],
);

/**
 * The amount one pays for covered services before their plan starts to share costs.
 *
 * @generated from message app.v1.Deductible
 */
export const Deductible = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Deductible",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "remaining", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "plan_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "in_network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "coverage_level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "as_of", kind: "message", T: Timestamp },
  ],
);

/**
 * GetOutOfPocketMaximumsRequest to retrieve a member's out-of-pocket-maximums.
 *
 * @generated from message app.v1.GetOutOfPocketMaximumsRequest
 */
export const GetOutOfPocketMaximumsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetOutOfPocketMaximumsRequest",
  [],
);

/**
 * GetOutOfPocketMaximumsResponse containing the out-of-pocket-maximums for the requested member.
 *
 * @generated from message app.v1.GetOutOfPocketMaximumsResponse
 */
export const GetOutOfPocketMaximumsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetOutOfPocketMaximumsResponse",
  () => [
    { no: 1, name: "moops", kind: "message", T: MOOP, repeated: true },
  ],
);

/**
 * The maximum amount (max-out-of-pocket) one will pay for covered services in a given period.
 *
 * @generated from message app.v1.MOOP
 */
export const MOOP = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.MOOP",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "remaining", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "period", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "plan_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "in_network", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "coverage_level", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "as_of", kind: "message", T: Timestamp },
  ],
);

/**
 * GetWebViewTokenRequest to get a webview token, suitable for making calls on behalf of the member.
 *
 * @generated from message app.v1.GetWebViewTokenRequest
 */
export const GetWebViewTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetWebViewTokenRequest",
  [],
);

/**
 * GetWebViewTokenResponse containing the webview token.
 *
 * @generated from message app.v1.GetWebViewTokenResponse
 */
export const GetWebViewTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetWebViewTokenResponse",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * CreateStripeCheckoutSessionRequest to set up a new Sheer Health user paying subscription through Stripe.
 *
 * @generated from message app.v1.CreateStripeCheckoutSessionRequest
 */
export const CreateStripeCheckoutSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreateStripeCheckoutSessionRequest",
  () => [
    { no: 1, name: "subscription_type", kind: "enum", T: proto3.getEnumType(CreateStripeCheckoutSessionRequest_SubscriptionType) },
    { no: 2, name: "promotion_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from enum app.v1.CreateStripeCheckoutSessionRequest.SubscriptionType
 */
export const CreateStripeCheckoutSessionRequest_SubscriptionType = /*@__PURE__*/ proto3.makeEnum(
  "app.v1.CreateStripeCheckoutSessionRequest.SubscriptionType",
  [
    {no: 0, name: "SUBSCRIPTION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SUBSCRIPTION_TYPE_MONTHLY", localName: "MONTHLY"},
    {no: 2, name: "SUBSCRIPTION_TYPE_ANNUAL", localName: "ANNUAL"},
  ],
);

/**
 * CreateStripeCheckoutSessionResponse containing the session details for the requested checkout.
 *
 * @generated from message app.v1.CreateStripeCheckoutSessionResponse
 */
export const CreateStripeCheckoutSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreateStripeCheckoutSessionResponse",
  () => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * CreateStandardStripeSubscriptionRequest to set up a new Sheer Health user free subscription through Stripe.
 *
 * @generated from message app.v1.CreateStandardStripeSubscriptionRequest
 */
export const CreateStandardStripeSubscriptionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreateStandardStripeSubscriptionRequest",
  [],
);

/**
 * CreateStandardStripeSubscriptionResponse containing the result of creating a free subscription.
 *
 * @generated from message app.v1.CreateStandardStripeSubscriptionResponse
 */
export const CreateStandardStripeSubscriptionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreateStandardStripeSubscriptionResponse",
  [],
);

/**
 * RegisterPayerRequest to register a payer for a new member.
 *
 * @generated from message app.v1.RegisterPayerRequest
 */
export const RegisterPayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterPayerRequest",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "primary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "primary_first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "primary_last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "primary_date_of_birth", kind: "message", T: Timestamp },
  ],
);

/**
 * RegisterPayerResponse containing the result of registering a payer for a new member.
 *
 * @generated from message app.v1.RegisterPayerResponse
 */
export const RegisterPayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterPayerResponse",
  () => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterUnsupportedCredentialsRequest to register credentials for a unsupported payer connection.
 *
 * @generated from message app.v1.RegisterUnsupportedCredentialsRequest
 */
export const RegisterUnsupportedCredentialsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterUnsupportedCredentialsRequest",
  () => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterUnsupportedCredentialsResponse containing the result of registering credentials for an unsupported payer connection.
 *
 * @generated from message app.v1.RegisterUnsupportedCredentialsResponse
 */
export const RegisterUnsupportedCredentialsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterUnsupportedCredentialsResponse",
  [],
);

/**
 * RegisterReferralSourceRequest to register a referral source for a new member.
 *
 * @generated from message app.v1.RegisterReferralSourceRequest
 */
export const RegisterReferralSourceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterReferralSourceRequest",
  () => [
    { no: 1, name: "referral_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "referral_notes", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterReferralSourceResponse containing the result of registering a referral source for a new member.
 *
 * @generated from message app.v1.RegisterReferralSourceResponse
 */
export const RegisterReferralSourceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterReferralSourceResponse",
  [],
);

/**
 * RegisterMemberSourceRequest to register the source through which the member found Sheer Health.
 *
 * @generated from message app.v1.RegisterMemberSourceRequest
 */
export const RegisterMemberSourceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterMemberSourceRequest",
  () => [
    { no: 1, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "attribution_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * RegisterMemberSourceResponse containing the result of registering the source through which the member found Sheer Health.
 *
 * @generated from message app.v1.RegisterMemberSourceResponse
 */
export const RegisterMemberSourceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterMemberSourceResponse",
  [],
);

/**
 * RegisterDemographicsRequest to register a new member's demographics.
 *
 * @generated from message app.v1.RegisterDemographicsRequest
 */
export const RegisterDemographicsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterDemographicsRequest",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "date_of_birth", kind: "message", T: Timestamp },
  ],
);

/**
 * RegisterDemographicsResponse containing the result of registering a new member's demographics.
 *
 * @generated from message app.v1.RegisterDemographicsResponse
 */
export const RegisterDemographicsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterDemographicsResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * RegisterHealthProfilesRequest to register a new member's health profiles.
 *
 * @generated from message app.v1.RegisterHealthProfilesRequest
 */
export const RegisterHealthProfilesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterHealthProfilesRequest",
  () => [
    { no: 1, name: "health_profiles", kind: "enum", T: proto3.getEnumType(HealthProfileType), repeated: true },
  ],
);

/**
 * RegisterHealthProfilesResponse containing the result of registering a new member's health profiles.
 *
 * @generated from message app.v1.RegisterHealthProfilesResponse
 */
export const RegisterHealthProfilesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RegisterHealthProfilesResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * ListConnectionsRequest to list connections for the current member.
 *
 * @generated from message app.v1.ListConnectionsRequest
 */
export const ListConnectionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListConnectionsRequest",
  [],
);

/**
 * ListConnectionsResponse containing the result of listing plan connections for the current member.
 *
 * @generated from message app.v1.ListConnectionsResponse
 */
export const ListConnectionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ListConnectionsResponse",
  () => [
    { no: 1, name: "connections", kind: "message", T: Connection, repeated: true },
  ],
);

/**
 * A connection to a plan that a member is enrolled in.
 *
 * @generated from message app.v1.Connection
 */
export const Connection = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.Connection",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "merchant_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "updated_at", kind: "message", T: Timestamp },
    { no: 7, name: "faulted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "types", kind: "enum", T: proto3.getEnumType(PayerType), repeated: true },
  ],
);

/**
 * EditConnectionRequest to modify non-credential fields on the connection.
 *
 * @generated from message app.v1.EditConnectionRequest
 */
export const EditConnectionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.EditConnectionRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * EditConnectionResponse containing the result of editing a connection for the current member.
 *
 * @generated from message app.v1.EditConnectionResponse
 */
export const EditConnectionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.EditConnectionResponse",
  [],
);

/**
 * JoinWaitlistRequest to waitlist a member who is trying to register.
 *
 * @generated from message app.v1.JoinWaitlistRequest
 */
export const JoinWaitlistRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.JoinWaitlistRequest",
  [],
);

/**
 * JoinWaitlistResponse containing the result of waitlisting a member who is trying to register.
 *
 * @generated from message app.v1.JoinWaitlistResponse
 */
export const JoinWaitlistResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.JoinWaitlistResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * ExpressBetaInterestRequest to express interest in opting into betas.
 *
 * @generated from message app.v1.ExpressBetaInterestRequest
 */
export const ExpressBetaInterestRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ExpressBetaInterestRequest",
  [],
);

/**
 * ExpressBetaInterestResponse containing the result of expressing interest in opting into betas.
 *
 * @generated from message app.v1.ExpressBetaInterestResponse
 */
export const ExpressBetaInterestResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ExpressBetaInterestResponse",
  () => [
    { no: 1, name: "member", kind: "message", T: Member },
  ],
);

/**
 * ConnectToPayerRequest to connect to a payer for a member.
 *
 * @generated from message app.v1.ConnectToPayerRequest
 */
export const ConnectToPayerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ConnectToPayerRequest",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * MFACodeResponse indicating that we need an MFA code to proceed with the connection.
 *
 * @generated from message app.v1.MFACodeResponse
 */
export const MFACodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.MFACodeResponse",
  () => [
    { no: 1, name: "request_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code_location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * MultipleChoiceResponse indicating that we need an answer to a multiple choice question to proceed with the connection.
 *
 * @generated from message app.v1.MultipleChoiceResponse
 */
export const MultipleChoiceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.MultipleChoiceResponse",
  () => [
    { no: 1, name: "request_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "question", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "options", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * MFASetupResponse indicating that MFA needs to be set up to proceed with the connection.
 *
 * @generated from message app.v1.MFASetupResponse
 */
export const MFASetupResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.MFASetupResponse",
  () => [
    { no: 1, name: "mfa_setup_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Success response to a connection request.
 *
 * @generated from message app.v1.SuccessfulConnectionResponse
 */
export const SuccessfulConnectionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.SuccessfulConnectionResponse",
  () => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ConnectToPayerResponse containing the result of connecting to a payer for a member.
 *
 * @generated from message app.v1.ConnectToPayerResponse
 */
export const ConnectToPayerResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ConnectToPayerResponse",
  () => [
    { no: 1, name: "success", kind: "message", T: SuccessfulConnectionResponse, oneof: "response" },
    { no: 2, name: "mfa", kind: "message", T: MFACodeResponse, oneof: "response" },
    { no: 3, name: "choice", kind: "message", T: MultipleChoiceResponse, oneof: "response" },
    { no: 4, name: "mfa_setup", kind: "message", T: MFASetupResponse, oneof: "response" },
  ],
);

/**
 * ProvideConnectionMFACodeRequest to provide the MFA code to satisfy the connection.
 *
 * @generated from message app.v1.ProvideConnectionMFACodeRequest
 */
export const ProvideConnectionMFACodeRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ProvideConnectionMFACodeRequest",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ProvideConnectionMFACodeResponse containing the result of providing the MFA code to satisfy the connection.
 *
 * @generated from message app.v1.ProvideConnectionMFACodeResponse
 */
export const ProvideConnectionMFACodeResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ProvideConnectionMFACodeResponse",
  [],
);

/**
 * ProvideConnectionChoiceRequest to provide the choice to satisfy the connection.
 *
 * @generated from message app.v1.ProvideConnectionChoiceRequest
 */
export const ProvideConnectionChoiceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ProvideConnectionChoiceRequest",
  () => [
    { no: 1, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "choice", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ProvideConnectionChoiceResponse containing the result of providing the choice to satisfy the connection.
 *
 * @generated from message app.v1.ProvideConnectionChoiceResponse
 */
export const ProvideConnectionChoiceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.ProvideConnectionChoiceResponse",
  [],
);

/**
 * GetSheerCardsRequest to get SH member card details for the account.
 *
 * @generated from message app.v1.GetSheerCardsRequest
 */
export const GetSheerCardsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetSheerCardsRequest",
  () => [
    { no: 1, name: "connection_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * GetSheerCardsResponse containing the SH member card details for the account.
 *
 * @generated from message app.v1.GetSheerCardsResponse
 */
export const GetSheerCardsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetSheerCardsResponse",
  () => [
    { no: 1, name: "payer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sheer_payer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "plan_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "members", kind: "message", T: GetSheerCardsResponse_SheerCard, repeated: true },
  ],
);

/**
 * All details from a given member's card.
 *
 * @generated from message app.v1.GetSheerCardsResponse.SheerCard
 */
export const GetSheerCardsResponse_SheerCard = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.GetSheerCardsResponse.SheerCard",
  () => [
    { no: 1, name: "member_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "member_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payer_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "front_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "back_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "GetSheerCardsResponse_SheerCard"},
);

/**
 * Request to confirm or deny enrollment as patient in provider organization.
 *
 * @generated from message app.v1.RecordPatientConsentRequest
 */
export const RecordPatientConsentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RecordPatientConsentRequest",
  () => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "opt_out", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Response to confirm or deny enrollment as patient in provider organization.
 *
 * @generated from message app.v1.RecordPatientConsentResponse
 */
export const RecordPatientConsentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.RecordPatientConsentResponse",
  [],
);

/**
 * CreatePlanAnalysisCheckoutSessionRequest to create a checkout session for a plan analysis.
 *
 * @generated from message app.v1.CreatePlanAnalysisCheckoutSessionRequest
 */
export const CreatePlanAnalysisCheckoutSessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreatePlanAnalysisCheckoutSessionRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * CreatePlanAnalysisCheckoutSessionResponse containing the result of creating a checkout session for a plan analysis.
 *
 * @generated from message app.v1.CreatePlanAnalysisCheckoutSessionResponse
 */
export const CreatePlanAnalysisCheckoutSessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "app.v1.CreatePlanAnalysisCheckoutSessionResponse",
  () => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

