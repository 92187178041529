import { AnalyticsBrowser } from '@segment/analytics-next';

import getCurrentEnv from '@/utils/getCurrentEnv';

const MockAnalytics = {
  load: () => {},
  track: () => {},
  page: () => {},
  identify: () => {},
  reset: () => {},
};

const writeKey =
  getCurrentEnv() === 'production'
    ? 'KW17Jd7nAvBpWnHSECn8rcvtCgSmGAF3'
    : 'OSdq8B2XPOrKpa13Hip60UjEb2YjMZv3';

const analytics =
  getCurrentEnv() === 'development'
    ? MockAnalytics
    : AnalyticsBrowser.load({ writeKey });

export default analytics;
