function getCurrentEnv() {
  let hostname;
  if (typeof window !== 'undefined') {
    hostname = window.location.hostname;
  }

  switch (hostname) {
    case 'localhost':
      return 'development';
    case 'beta.scratch.sheerhealth.com':
      return 'beta_scratch';
    case 'app.scratch.sheerhealth.com':
      return 'scratch';
    case 'beta.staging.sheerhealth.com':
      return 'beta_staging';
    case 'app.staging.sheerhealth.com':
      return 'staging';
    case 'beta.sheerhealth.com':
      return 'beta_production';
    case 'app.sheerhealth.com':
      return 'production';
    default:
      break;
  }
}

export function getCurrentDomain() {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }
}

export default getCurrentEnv;
